import React from "react";
import { Container } from "react-bootstrap";
import useScrollPosition from "../../../../hooks/useScrollPosition";
import ScrollLink from "../../atoms/ScrollLink/ScrollLink";
import NavListDesktop from "../../molecules/NavListDesktop/NavListDesktop";
import ButtonLink from "../../atoms/ButtonLink/ButtonLink";
import { menuLinks } from "../../../../data/menuLinks";
import { CLAIM } from "../../../../constants";
import "./NavbarDesktop.scss";

const NavbarDesktop = () => {
  const { offset } = useScrollPosition();
  const isScrolled = offset > 45;
  const navbarDesktopClass = `app-navbar-desktop ${isScrolled ? "scrolled" : ""}`;
  const logoPath = isScrolled ? "/images/svg/navbar-logo-scrolled.svg" : "/images/svg/navbar-logo.svg";

  return (
    <section className={navbarDesktopClass}>
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="navbar-content">
            <ScrollLink to="root" className="navbar-logo">
              <img src={logoPath} alt="Buffswap logo" />
            </ScrollLink>
            <NavListDesktop list={menuLinks} />
            <ButtonLink url={CLAIM} variant="outlined" color={isScrolled ? "secondary" : "primary"}>
              Claim
            </ButtonLink>
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default NavbarDesktop;
