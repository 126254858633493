import React, { useState } from "react";
import { Link } from "react-scroll";
import { IScrollLink } from "../../../../shared/interfaces/IScrollLink";
import { MOBILE_BREAKPOINT } from "../../../../constants";
import useViewport from "../../../../hooks/useViewport";
import HoverIcon from "../HoverIcon/HoverIcon";
import "./ScrollLink.scss";

const ScrollLink = ({
  to,
  offsetTopDesktop = -45,
  offsetTopMobile = 0,
  children,
  icon = "",
  altIconText = "link icon",
  iconHovers = true,
  iconOnLeft = false,
  disabled = false,
  className = "",
  smooth = true,
  duration = 250,
  onClick
}: IScrollLink) => {
  const [hoverState, setHoverState] = useState<"default" | "hover">("default");
  const { witdh } = useViewport();

  const isHovered = hoverState === "hover";
  const hasIcon = icon !== "";
  const isMobile = witdh < MOBILE_BREAKPOINT;
  const offsetTop = isMobile ? offsetTopMobile : offsetTopDesktop;

  const setDefault = () => setHoverState("default");
  const setHover = () => setHoverState("hover");

  const scrollLinkClass = `buff-scroll-link ${className} ${disabled ? "disabled" : ""}`;

  return (
    <Link
      to={to}
      offset={offsetTop}
      className={scrollLinkClass}
      onMouseOver={setHover}
      onMouseLeave={setDefault}
      smooth={smooth}
      duration={duration}
      onClick={onClick}
    >
      {iconOnLeft && hasIcon && (
        <HoverIcon
          className="link-left-icon"
          iconPath={icon}
          altText={altIconText}
          isHover={isHovered}
          doHover={iconHovers}
        />
      )}
      {children}
      {!iconOnLeft && hasIcon && (
        <HoverIcon
          className="link-right-icon"
          iconPath={icon}
          altText={altIconText}
          isHover={isHovered}
          doHover={iconHovers}
        />
      )}
    </Link>
  );
};

export default ScrollLink;
