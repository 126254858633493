import { BUFFS_TOKEN_ADDRESS, BUFFS_TOKEN_SYMBOL, BUFFS_TOKEN_DECIMALS } from "../constants";

const token = {
  address: BUFFS_TOKEN_ADDRESS ?? "error",
  symbol: BUFFS_TOKEN_SYMBOL ?? "err",
  decimals: BUFFS_TOKEN_DECIMALS ?? 4,
  image: `${window.location.origin}/assets/Buffs-sign-512.png`
};

export const addBuffsToMetamask = async () => {
  const ethereum = (window as any).ethereum;
  if (!ethereum) {
    alert("Metamask is not installed");
    return;
  }

  try {
    await ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: { ...token }
      }
    });
  } catch (error) {
    console.error(error);
  }
};
