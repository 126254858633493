import React from "react";
import { IRoadmapItem } from "../../../../shared/interfaces/IRoadmapItem";
import RoadmapItemIcon from "../RoadmapItemIcon/RoadmapItemIcon";
import useIntensityTransform from "../../../../hooks/useIntensityTransform";
import "./RoadmapItem.scss";

const RoadmapItem = ({ status = "done", text, className = "" }: IRoadmapItem) => {
  const { tagRef, intensity, scaleIntensity } = useIntensityTransform([10, 25], [65, 80], 0.5, 1);
  const transformScale = `scale(${scaleIntensity}, ${scaleIntensity})`;

  return (
    <div
      ref={tagRef}
      style={{
        opacity: intensity,
        transform: transformScale
      }}
      className={`roadmap-item ${className}`}
    >
      <p className="s3 roadmap-item-text">{text}</p>
      <RoadmapItemIcon status={status} className="roadmap-item-icon" />
    </div>
  );
};

export default RoadmapItem;
