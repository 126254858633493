import { centerOfContainer } from "./centerOfContainer";

export const calcDeviation = (
  containerWidth: number,
  containerHeight: number,
  mouseX: number,
  mouseY: number,
  deviationIntensity: number,
  decimalPlaces: number = 2
) => {
  const { centerX, centerY } = centerOfContainer(containerWidth, containerHeight);
  const xDeviation = (mouseX / centerX - 1) * containerWidth * deviationIntensity;
  const yDeviation = (mouseY / centerY - 1) * containerHeight * deviationIntensity;
  return {
    xDeviation: (xDeviation * -1).toFixed(decimalPlaces),
    yDeviation: (yDeviation * -1).toFixed(decimalPlaces)
  };
};
