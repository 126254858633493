import React from "react";
import { Container } from "react-bootstrap";
import ButtonLink from "../../components/atoms/ButtonLink/ButtonLink";
import Button from "../../components/atoms/Button/Button";
import useViewport from "../../../hooks/useViewport";
import { PANCAKE_BUY, PANCAKE_VIEW_CHART, MOBILE_BREAKPOINT } from "../../../constants";
import { addBuffsToMetamask } from "../../../services/Metamask";
import "./PancakeSwap.scss";

const aosBaseAnimConfig = {
  "data-aos": "zoom-in",
  "data-aos-easing": "linear",
  "data-aos-duration": "350",
  "data-aos-once": "false"
};

const PancakeSwap = () => {
  const { witdh } = useViewport();
  const isMobile = witdh < MOBILE_BREAKPOINT;

  const animationDesktopProps = {
    ...(isMobile ? {} : { ...aosBaseAnimConfig, "data-aos-offset": "300" })
  };

  const animationMobileProps = {
    ...(isMobile ? { ...aosBaseAnimConfig, "data-aos-offset": "100" } : {})
  };

  return (
    <section id="pancake-swap" className="pancake-swap">
      <Container fluid="xxl">
        <Container fluid="xl" className="pancake-swap-container">
          <div {...aosBaseAnimConfig} data-aos-offset="300" className="pancake-swap-content">
            <div className="hero-box">
              <img
                src="/images/png/hero-pancake-swap-desktop.png"
                alt="section logo"
                className="desktop-md hero-image"
              />
              <img
                src="/images/png/hero-pancake-swap-mobile.png"
                alt="section logo"
                className="mobile-md hero-image"
              />
              <div className="hero-blur" />
            </div>

            <h2 className="header">Pancake Swap</h2>
            <p className="description">
              Metamask smart contract. Transactions from the official projects portfolio to your wallet.
            </p>

            <div {...animationDesktopProps} className="pancake-swap-buttons">
              <div {...animationMobileProps}>
                <ButtonLink url={PANCAKE_BUY} color="secondary" className="pancake-swap-btn">
                  Buy
                </ButtonLink>
              </div>
              <div {...animationMobileProps}>
                <Button color="secondary" className="pancake-swap-btn" onClick={addBuffsToMetamask}>
                  Add token to metamask
                </Button>
              </div>
              <div {...animationMobileProps}>
                <ButtonLink url={PANCAKE_VIEW_CHART} color="secondary" className="pancake-swap-btn">
                  View chart
                </ButtonLink>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default PancakeSwap;
