import React from "react";
import NavListMobile from "../../molecules/NavListMobile/NavListMobile";
import ScrollLink from "../../atoms/ScrollLink/ScrollLink";
import ButtonLink from "../../atoms/ButtonLink/ButtonLink";
import SocialMediaBar from "../SocialMediaBar/SocialMediaBar";
import { IMobileMenu } from "../../../../shared/interfaces/IMobileMenu";
import { menuLinks } from "../../../../data/menuLinks";
import { socialMedia } from "../../../../data/socialMedia";
import { BUY_ON_PANCAKE_SWAP } from "../../../../constants";
import "./MobileMenu.scss";

const MobileMenu = ({ close }: IMobileMenu) => {
  return (
    <div className="mobile-menu">
      <div className="menu-header">
        <ScrollLink to="root" onClick={close}>
          <img src="/images/svg/navbar-logo.svg" alt="Buffswap logo" />
        </ScrollLink>
        <button onClick={close}>
          <img src="/images/svg/close-icon.svg" alt="Close" />
        </button>
      </div>

      <NavListMobile list={menuLinks} close={close} />
      <ButtonLink
        url={BUY_ON_PANCAKE_SWAP}
        size="size-big"
        color="third"
        variant="outlined"
        className="pancake-btn"
      >
        Buy on pancake swap
      </ButtonLink>
      <SocialMediaBar
        socialMediaList={socialMedia}
        socialIconVariant="mobileMenu"
        className="mobile-menu-social-bar"
      />
    </div>
  );
};

export default MobileMenu;
