import { useEffect, useState } from "react";

const useScrollPosition = () => {
  const [offset, setOffset] = useState(window.pageYOffset);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setOffset(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { offset };
};

export default useScrollPosition;
