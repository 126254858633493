import React from "react";
import { Container } from "react-bootstrap";
import MovableImage from "../../components/atoms/MovableImage/MovableImage";
import { DEVIATION_INTENSITY, movableArrowImages } from "../../../data/movableArrowImages";
import "./BlockchainInfo.scss";

const aosAnimBaseConfig = {
  "data-aos": "fade-up",
  "data-aos-easing": "linear",
  "data-aos-duration": "350",
  "data-aos-once": "false",
  "data-aos-offset": "200"
};

const BlockchainInfo = () => {
  return (
    <section id="blockchain-info" className="blockchainInfo">
      <Container fluid="xxl">
        <Container fluid="xl" className="blockchainInfo-container">
          <div className="blockchainInfo-coin-desktop-wrapper desktop-md">
            <img src="/images/png/blockchain-info-coin-desktop.png" alt="coin" className="coin" />
            <div className="coin-blur" />
            {movableArrowImages.map(({ src, alt, className }) => (
              <MovableImage
                key={Math.random()}
                src={src}
                alt={alt}
                deviationIntensity={DEVIATION_INTENSITY}
                className={className}
              />
            ))}
          </div>

          <div className="blockchainInfo-content">
            <h1 {...aosAnimBaseConfig} className="header">
              The Bridge between virtual assets and blockchain
            </h1>
            <div {...aosAnimBaseConfig}>
              <p className="description">
                BuffSwap is the first bridge between virtual gaming assets and blockchain. <br /> The
                connector that associate billions of esport enthusiasts and crypto investors with a potential
                1000 bilion-dollar market
              </p>
            </div>
          </div>

          <div className="blockchainInfo-coin-mobile-wrapper mobile-md">
            <img src="/images/png/blockchain-info-coin-mobile.png" alt="coin" className="coin" />
            <div className="coin-blur" />
            <img
              src="/images/svg/blockchain-info-blue-arrow-mobile.svg"
              alt="blue arrow"
              className="blue-arrow"
            />
            <img
              src="/images/svg/blockchain-info-green-arrow-mobile.svg"
              alt="green arrow"
              className="green-arrow"
            />
            <img
              src="/images/svg/blockchain-info-white-arrow-mobile.svg"
              alt="white arrow"
              className="white-arrow"
            />
            <img
              src="/images/svg/blockchain-info-cyan-arrow-mobile.svg"
              alt="cyan arrow"
              className="cyan-arrow"
            />
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default BlockchainInfo;
