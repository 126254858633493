import { MEDIUM, TWITTER, YOUTUBE, TELEGRAM_NEWS, TELEGRAM_CHAT } from "../constants";

export const socialMedia = [
  {
    logoPaths: {
      stickyBar: "/images/svg/social-medium-sticky-bar-icon.svg",
      footer: "/images/svg/social-medium-footer-icon.svg",
      mobileMenu: "/images/svg/social-medium-mobile-menu-icon.svg"
    },
    url: MEDIUM
  },
  {
    logoPaths: {
      stickyBar: "/images/svg/social-twitter-sticky-bar-icon.svg",
      footer: "/images/svg/social-twitter-footer-icon.svg",
      mobileMenu: "/images/svg/social-twitter-mobile-menu-icon.svg"
    },
    url: TWITTER
  },
  {
    logoPaths: {
      stickyBar: "/images/svg/social-youtube-sticky-bar-icon.svg",
      footer: "/images/svg/social-youtube-footer-icon.svg",
      mobileMenu: "/images/svg/social-youtube-mobile-menu-icon.svg"
    },
    url: YOUTUBE
  },
  {
    logoPaths: {
      stickyBar: "/images/svg/social-telegram-sticky-bar-icon.svg",
      footer: "/images/svg/social-telegram-footer-icon.svg",
      mobileMenu: "/images/svg/social-telegram-mobile-menu-icon.svg"
    },
    channels: [
      {
        label: "#BUFFSNews",
        url: TELEGRAM_NEWS
      },
      {
        label: "#BUFFSChat",
        url: TELEGRAM_CHAT
      }
    ]
  }
];
