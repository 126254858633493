import React from "react";
import { ISocialIconLink } from "../../../../shared/interfaces/ISocialIconLink";
import "./SocialIconLink.scss";

const SocialIconLink = ({ logoPath, withTooltip = false, url = "", className = "" }: ISocialIconLink) => {
  if (withTooltip)
    return <img src={logoPath} alt="social media button" className={`social-icon-link ${className}`} />;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={`social-icon-link ${className}`}>
      <img src={logoPath} alt="social media button" />
    </a>
  );
};

export default SocialIconLink;
