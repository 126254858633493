import React from "react";
import { Container } from "react-bootstrap";
import ButtonLink from "../../components/atoms/ButtonLink/ButtonLink";
import PartnershipsBar from "../../components/molecules/PartnershipsBar/PartnershipsBar";
import { partnershipsImages } from "../../../data/partnershipsImages";
import { BUY_ON_PANCAKE_SWAP } from "../../../constants";
import "./Hero.scss";

const Hero = () => {
  return (
    <section id="hero" className="hero">
      <Container fluid="xxl">
        <img
          src="/images/png/hero-bg-coins-left-desktop.png"
          alt="Coins"
          className="hero-coins-bg-left desktop-md"
        />
        <img
          src="/images/png/hero-bg-coins-left-mobile.png"
          alt="Coins"
          className="hero-coins-bg-left-mobile mobile-md"
        />
        <Container fluid="xl">
          <div className="navbar-logo-splash desktop-md" />
          <div className="sticky-social-media-splash desktop-md" />

          <div className="hero-container">
            <div className="hero-content">
              <div className="header-box">
                <img src="/images/svg/buffswap-logo.svg" alt="logo" className="desktop-md header" />
                <img src="/images/svg/buffswap-logo-mobile.svg" alt="logo" className="mobile-md header" />
                <p className="s1 subheader">The Bridge between virtual assets and blockchain</p>
                <div className="header-blur" />
              </div>
              <div className="buttons-box">
                <ButtonLink
                  url={BUY_ON_PANCAKE_SWAP}
                  size="size-big"
                  color="primary"
                  icon="/images/png/pancakeswap-cake.png"
                >
                  BUY ON PANCAKE SWAP
                </ButtonLink>
                <div className="mock-btn" />
                {/* <ButtonLink
                  url="#buff-video"
                  target="_self"
                  size="size-big"
                  color="secondary"
                  icon="/images/svg/arrow-right-icon.svg"
                >
                  Watch trailer
                </ButtonLink> */}
              </div>
            </div>
          </div>
          <div className="hero-partnerships">
            <PartnershipsBar partnershipsImages={partnershipsImages} />
          </div>
        </Container>
        <img
          src="/images/png/hero-bg-coins-right-desktop.png"
          alt="Coins"
          className="hero-coins-bg-right desktop-md"
        />
        <img
          src="/images/png/hero-bg-coins-right-mobile.png"
          alt="Coins"
          className="hero-coins-bg-right-mobile mobile-md"
        />
      </Container>
    </section>
  );
};

export default Hero;
