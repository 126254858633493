import React from "react";
import { Container } from "react-bootstrap";
import ButtonLink from "../../components/atoms/ButtonLink/ButtonLink";
import useViewport from "../../../hooks/useViewport";
import { LITEPAPER, MOBILE_BREAKPOINT } from "../../../constants";
import "./Litepaper.scss";

const aosAnimBaseConfig = {
  "data-aos": "zoom-in",
  "data-aos-easing": "linear",
  "data-aos-duration": "300",
  "data-aos-once": "false",
  "data-aos-offset": "50"
};

const Litepaper = () => {
  const { witdh } = useViewport();
  const isMobile = witdh < MOBILE_BREAKPOINT;

  const animationMobileProps = {
    ...(isMobile ? aosAnimBaseConfig : {})
  };

  return (
    <section id="litepaper" className="litepaper">
      <div className="litepaper-outline desktop-md" />
      <Container fluid="xxl">
        <Container fluid="xl" className="litepaper-container">
          <div className="litepaper-image-box desktop-md">
            <img
              src="/images/png/litepaper-book-desktop.png"
              alt="litepaper book"
              className="litepaper-image"
            />
            <div className="litepaper-blur" />
            <img
              src="/images/png/litepaper-shadow-desktop.png"
              alt="litepaper book shadow"
              className="litepaper-shadow"
            />
          </div>

          <div className="litepaper-content">
            <div {...animationMobileProps}>
              <h1 className="haeder">Check the Litepaper</h1>
              <p className="text">Buff your income with $BUFFS! Read how it works exactly!</p>
            </div>

            <div className="litepaper-image-box mobile-md">
              <img
                src="/images/png/litepaper-book-mobile.png"
                alt="litepaper book"
                className="litepaper-image"
              />
              <div className="litepaper-blur" />
              <img
                src="/images/png/litepaper-shadow-mobile.png"
                alt="litepaper book shadow"
                className="litepaper-shadow"
              />
            </div>

            <div {...animationMobileProps}>
              <ButtonLink
                url={LITEPAPER}
                color="secondary"
                icon="/images/svg/arrow-right-icon-small.svg"
                className="litepaper-button"
              >
                Go to litepaper
              </ButtonLink>
            </div>
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default Litepaper;
