import React from "react";
import { Container } from "react-bootstrap";
import { useCasesList } from "../../../data/useCasesList";
import UseCaseCard from "../../components/atoms/UseCaseCard/UseCaseCard";
import "./UseCases.scss";

const UseCases = () => {
  return (
    <section id="use-cases" className="use-cases">
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="use-cases-cards">
            {useCasesList.map(({ title, description, iconPath }) => (
              <UseCaseCard
                key={Math.random()}
                title={title}
                description={description}
                iconPath={iconPath}
                className="use-cases-card"
              />
            ))}
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default UseCases;
