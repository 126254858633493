import React from "react";
import { IMobileHamburger } from "../../../../shared/interfaces/IMobileHamburger";
import "./MobileHamburger.scss";

const MobileHamburger = ({ onClick }: IMobileHamburger) => {
  return (
    <button className="mobile-hamburger" onClick={onClick}>
      <img src="/images/svg/hamburger.svg" alt="Hamburger button" />
    </button>
  );
};

export default MobileHamburger;
