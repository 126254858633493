import React from "react";
import { RoadmapItemStatus } from "../../../../shared/types/RoadmapItem.status";
import { RoadmapItemStatus as Status } from "../../../../shared/enums/RoadmapItemStatus.enum";
import RoadmapItemLoader from "../RoadmapItemLoader/RoadmapItemLoader";

interface IRoadmapItemIcon {
  status?: RoadmapItemStatus;
  className?: string;
}

const RoadmapItemIcon = ({ status = "done", className = "" }: IRoadmapItemIcon) => {
  switch (status) {
    case Status.DONE:
      return <img src="/images/svg/check-icon.svg" alt="check mark icon" className={className} />;
    case Status.LOADING:
      return <RoadmapItemLoader className={className} />;
    default:
      return null;
  }
};

export default RoadmapItemIcon;
