import React, { useState } from "react";
// import useScrollLock from "../../../../hooks/useScrollLock";
import MobileHamburger from "../../atoms/MobileHamburger/MobileHamburger";
import MobileMenu from "../MobileMenu/MobileMenu";
import "./NavbarMobile.scss";

const NavbarMobile = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  // const { lockScroll, unlockScroll } = useScrollLock();

  const openMenu = () => {
    // lockScroll();
    setIsMenuVisible(true);
  };
  const closeMenu = () => {
    // unlockScroll();
    setIsMenuVisible(false);
  };

  return (
    <section className="app-navbar-mobile">
      <MobileHamburger onClick={openMenu} />
      {isMenuVisible && <MobileMenu close={closeMenu} />}
    </section>
  );
};

export default NavbarMobile;
