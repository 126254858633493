export const useCasesList = [
  {
    title: "Our mission",
    description: "BuffSwap focuses mainly on implementing $BUFFS token to gaming platforms.",
    iconPath: "/images/svg/use-case-icon1.svg"
  },
  {
    title: "Prizes",
    description:
      "Each gaming platform with virtual assets after BUFFS Bridge implementing might expand the gaming to the next prize level.",
    iconPath: "/images/svg/use-case-icon2.svg"
  },
  {
    title: "Token",
    description: "The more gaming platforms and users partnered, the more tokens are being burned.",
    iconPath: "/images/svg/use-case-icon3.svg"
  },
  {
    title: "Passive Income",
    description:
      "BuffSwap provides a reward system not only for those who actively participate on the platform, but also for those who keep their funds there.",
    iconPath: "/images/svg/use-case-icon4.svg"
  }
];
