import React, { useState } from "react";
import { ILink } from "../../../../shared/interfaces/ILink";
import HoverIcon from "../HoverIcon/HoverIcon";
import "./Link.scss";

const Link = ({
  url,
  target = "_blank",
  children,
  icon = "",
  altIconText = "link icon",
  iconHovers = true,
  iconOnLeft = false,
  download = false,
  disabled = false,
  className = ""
}: ILink) => {
  const [hoverState, setHoverState] = useState<"default" | "hover">("default");
  const isHovered = hoverState === "hover";
  const hasIcon = icon !== "";

  const setDefault = () => setHoverState("default");
  const setHover = () => setHoverState("hover");

  const linkClass = `buff-link ${className} ${disabled ? "disabled" : ""}`;

  return (
    <a
      href={url}
      className={linkClass}
      rel="noreferrer noopener"
      target={target}
      download={download}
      onMouseOver={setHover}
      onMouseLeave={setDefault}
    >
      {iconOnLeft && hasIcon && (
        <HoverIcon
          className="link-left-icon"
          iconPath={icon}
          altText={altIconText}
          isHover={isHovered}
          doHover={iconHovers}
        />
      )}
      {children}
      {!iconOnLeft && hasIcon && (
        <HoverIcon
          className="link-right-icon"
          iconPath={icon}
          altText={altIconText}
          isHover={isHovered}
          doHover={iconHovers}
        />
      )}
    </a>
  );
};

export default Link;
