import { IMenuItem } from "../shared/interfaces/IMenuItem";

export const menuLinks: IMenuItem[] = [
  {
    label: "Home",
    sectionID: "hero",
    offsetTopDesktop: -45,
    offsetTopMobile: 0
  },
  {
    label: "Token",
    sectionID: "token-details",
    offsetTopDesktop: 0,
    offsetTopMobile: 0
  },
  {
    label: "Litepaper",
    sectionID: "litepaper",
    offsetTopDesktop: -45,
    offsetTopMobile: 0
  },
  {
    label: "Roadmap",
    sectionID: "roadmap",
    offsetTopDesktop: -45,
    offsetTopMobile: 0
  }
];
