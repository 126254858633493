import React from "react";
import { Container } from "react-bootstrap";
import Lottie from "react-lottie";
import RoadmapHeader from "../../components/atoms/RoadmapHeader/RoadmapHeader";
import RoadmapQuarterList from "../../components/molecules/RoadmapQuarterList/RoadmapQuarterList";
import { roadmapPlan } from "../../../data/roadmapPlan";
import "./Roadmap.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: require("./animation.json"),
  renderer: "svg"
};

const Roadmap = () => {
  return (
    <section id="roadmap" className="roadmap">
      <div className="roadmap-sticky-overlay">
        <div className="roadmap-lines-animation">
          <Lottie options={defaultOptions} />
        </div>
        <Container fluid="xl">
          <img src="/images/svg/roadmap-line.svg" alt="roadmap line" className="line desktop-md" />
          <img src="/images/svg/roadmap-line-mobile.svg" alt="roadmap line" className="line mobile-md" />
          <RoadmapHeader className="roadmap-header" />
        </Container>
      </div>

      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="roadmap-list">
            {roadmapPlan.map(({ quarterYear, planList }) => (
              <RoadmapQuarterList key={Math.random()} quarterYear={quarterYear} quarterList={planList} />
            ))}
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default Roadmap;
