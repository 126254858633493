import { RoadmapItemStatus } from "../shared/enums/RoadmapItemStatus.enum";

export const roadmapQ32021 = {
  quarterYear: "Q3 2021",
  planList: [
    {
      text: "Alpha Version",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Certik Audit",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Pre-launch Marketing Campaign",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Establishing Relations with Investors and Strategic",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Partner Foundation of BuffSwap Market Research ",
      status: RoadmapItemStatus.DONE
    },

    {
      text: "Core Team",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "5 Year Plan",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Validation of Business Model",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Website V.1",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Marketing Strategy",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Bridge Testing",
      status: RoadmapItemStatus.DONE
    }
  ]
};

export const roadmapQ42021 = {
  quarterYear: "Q4 2021",
  planList: [
    {
      text: "Private Sale",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Community Sale",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Litepaper Publication",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Listing DEX Pancake Swap",
      status: RoadmapItemStatus.DONE
    }
  ]
};

export const roadmap2022 = {
  quarterYear: "2022",
  planList: [
    {
      text: "Governance research and development",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Beta Version Bridge Buff-Swap",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Testing Beta version of the Bridge",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Strategic plan for Bridge developmen",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Beta version Website V.2",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Analyzing the legal opinions from the various countries",
      status: RoadmapItemStatus.DONE
    },
    {
      text: "Establishing contacts with potential collaborative platforms",
      status: RoadmapItemStatus.DONE
    }
  ]
};

export const roadmap2023 = {
  quarterYear: "2023",
  planList: [
    {
      text: "Buff-Swap Bridge Testing",
      status: RoadmapItemStatus.LOADING
    },
    {
      text: "Consultation with players and platforms",
      status: RoadmapItemStatus.LOADING
    },
    {
      text: "Launch Website V2",
      status: RoadmapItemStatus.LOADING
    },
    {
      text: "Official Bridge launch ",
      status: RoadmapItemStatus.LOADING
    },
    {
      text: "E-Sport sponsorship",
      status: RoadmapItemStatus.LOADING
    },
    {
      text: "Cooperation with Youtubers and Streamers",
      status: RoadmapItemStatus.LOADING
    },
    {
      text: "Empowering Community",
      status: RoadmapItemStatus.LOADING
    },
    {
      text: "Strategic gaming partnerships",
      status: RoadmapItemStatus.LOADING
    },
    {
      text: "Presentation of the plan for 2023",
      status: RoadmapItemStatus.LOADING
    }
  ]
};

export const roadmapPlan = [roadmapQ32021, roadmapQ42021, roadmap2022, roadmap2023];
