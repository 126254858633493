import React from "react";
import { Container } from "react-bootstrap";
import Button from "../../components/atoms/Button/Button";
import { addBuffsToMetamask } from "../../../services/Metamask";
import {
  BUFFS_TOKEN_NAME,
  BUFFS_TOKEN_SYMBOL,
  BUFFS_TOKEN_DECIMALS,
  BUFFS_TOKEN_ADDRESS
} from "../../../constants";
import "./TokenDetails.scss";

const aosAnimFaceConfig = {
  "data-aos-offset": "600",
  "data-aos-easing": "ease-in-sine",
  "data-aos-duration": "350",
  "data-aos-anchor-placement": "top-bottom",
  "data-aos-once": "false"
};

const aosAnimBaseConfig = {
  "data-aos": "fade-up",
  "data-aos-easing": "linear",
  "data-aos-duration": "350",
  "data-aos-once": "false"
};

const TokenDetails = () => {
  return (
    <section id="token-details" className="token-details">
      <div data-aos="fade-right" {...aosAnimFaceConfig} className="left-face-anim-wrapper desktop-md">
        <div className="left-face-box">
          <img src="/images/svg/buff-face.svg" alt="section shape" className="face-image" />
          <div className="left-face-blur" />
          <div className="left-face-blur" />
        </div>
      </div>
      <Container fluid="xxl">
        <Container fluid="xl" className="token-details-content">
          <div {...aosAnimBaseConfig} data-aos-offset="200" className="hero-box">
            <img
              src="/images/svg/hero-token-details-desktop.svg"
              alt="section logo"
              className="desktop-md hero-image"
            />
            <img
              src="/images/svg/hero-token-details-mobile.svg"
              alt="section logo"
              className="mobile-md hero-image"
            />
            <div className="hero-blur" />
          </div>

          <p {...aosAnimBaseConfig} data-aos-offset="200" className="token-details-text text-center">
            Token details: <br />
            Name: {BUFFS_TOKEN_NAME} <br />
            SYMBOL: {BUFFS_TOKEN_SYMBOL} <br />
            DECIMALS: {BUFFS_TOKEN_DECIMALS}
          </p>

          <p {...aosAnimBaseConfig} data-aos-offset="200" className="token-contract-adress text-center">
            ERC-20 Contract Adress: <br />
            <span>{BUFFS_TOKEN_ADDRESS}</span> <br />
            BSC Contract Adress: <br />
            <span>{BUFFS_TOKEN_ADDRESS}</span>
          </p>

          <div {...aosAnimBaseConfig} data-aos-offset="200">
            <Button
              color="secondary"
              icon="/images/svg/arrow-right-icon-small.svg"
              className="token-details-button"
              onClick={addBuffsToMetamask}
            >
              Add Buff-swap token
            </Button>
          </div>
        </Container>
      </Container>
      <div data-aos="fade-left" {...aosAnimFaceConfig} className="right-face-anim-wrapper desktop-md">
        <div className="right-face-box">
          <img src="/images/svg/buff-face.svg" alt="section shape" className="face-image" />
          <div className="right-face-blur" />
          <div className="right-face-blur" />
        </div>
      </div>
    </section>
  );
};

export default TokenDetails;
