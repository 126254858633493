import React from "react";
import { Link } from "react-scroll";
import { IMenuItem } from "../../../../shared/interfaces/IMenuItem";
import useScrollPosition from "../../../../hooks/useScrollPosition";
import "./MenuItem.scss";

const MenuItem = ({
  label,
  sectionID,
  offsetTopDesktop = -45,
  offsetTopMobile = 0,
  smooth = true,
  duration = 250,
  isMobile = false,
  onClick
}: IMenuItem) => {
  const { offset } = useScrollPosition();
  const offsetTop = isMobile ? offsetTopMobile : offsetTopDesktop;

  const isScrolled = offset > 45;
  const menuItemClass = `menu-item ${isScrolled ? "menu-item-scrolled" : ""}`;
  const menuItemActiveClass = ` ${isScrolled ? "menu-item-active-scrolled" : "menu-item-active"}`;

  const itemClass = isMobile ? "menu-mobile-item" : menuItemClass;
  const itemActiveClass = isMobile ? "menu-mobile-item-active" : menuItemActiveClass;

  return (
    <Link
      className={itemClass}
      activeClass={itemActiveClass}
      to={sectionID}
      offset={offsetTop}
      smooth={smooth}
      duration={duration}
      onClick={onClick}
      spy
    >
      {label}
    </Link>
  );
};

export default MenuItem;
