import React, { useRef, useState } from "react";
import { IMovableImage } from "../../../../shared/interfaces/IMovableImage";
import { useRect } from "../../../../hooks/useRect";
import { calcDeviation } from "../../../../services/calcDeviation";
import "./MovableImage.scss";

const MovableImage = ({
  src,
  alt = "movable image",
  deviationIntensity = 0.05,
  className = ""
}: IMovableImage) => {
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [tranformProperty, setTransformProperty] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);
  const { width, height } = useRect(containerRef);

  const handleMouseMove = (e: any) => {
    setContainerSize({ width, height });
    const { layerX: x, layerY: y } = e?.nativeEvent;

    const { xDeviation, yDeviation } = calcDeviation(
      containerSize.width,
      containerSize.height,
      x,
      y,
      deviationIntensity
    );

    setTransformProperty(`translate3d(${xDeviation}px, ${yDeviation}px, 0px)`);
  };

  const handleMouseLeave = () => {
    setTransformProperty("");
  };

  return (
    <div
      ref={containerRef}
      className={`movable-image-container ${className}`}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <img src={src} alt={alt} className="movable-image" style={{ transform: tranformProperty }} />
    </div>
  );
};

export default MovableImage;
