import React from "react";
import { RectResult } from "../shared/types/RectResult";
import { getRect } from "../services/getRect";
import { useWindowDimensions } from "./useWindowDimensions";

type IntensityTransformResult = {
  tagRef: React.RefObject<HTMLDivElement>;
  intensity: number;
  scaleIntensity: number;
};

const ratioShowing = (startShowingPercent: number, endShowingPercent: number, current: number) =>
  1 - (current - endShowingPercent) / (startShowingPercent - endShowingPercent);

const ratioHiding = (startHidingPercent: number, endHidingPercent: number, current: number) =>
  1 - (current - startHidingPercent) / (endHidingPercent - startHidingPercent);

const changeScaleOfRange = (value: number, minRange: number, maxRange: number) =>
  value * (maxRange - minRange) + minRange;

const useIntensityTransform = (
  [startShowingPercent, endShowingPercent]: number[],
  [startHidingPercent, endHidingPercent]: number[],
  minScaleValue: number = 0,
  maxScaleValue: number = 1
): IntensityTransformResult => {
  const { height } = useWindowDimensions();
  const tagRef = React.useRef<HTMLDivElement>(null);
  const [elementRect, setElementRect] = React.useState<RectResult>(
    tagRef && tagRef.current ? getRect(tagRef.current) : getRect()
  );
  const [intensity, setIntensity] = React.useState<number>(1); //0-1
  const [scaleIntensity, setScaleIntensity] = React.useState<number>(1);

  const startShowingPercentIndex = startShowingPercent / 100;
  const endShowingPercentIndex = endShowingPercent / 100;
  const startShwoingPosition = height - height * startShowingPercentIndex;
  const endShwoingPosition = height - height * endShowingPercentIndex;

  const startHidingPercentIndex = startHidingPercent / 100;
  const endHidingPercentIndex = endHidingPercent / 100;
  const startHidingPosition = height - height * startHidingPercentIndex;
  const endHidingPosition = height - height * endHidingPercentIndex;

  React.useEffect(() => {
    if (!tagRef.current) return;
    setElementRect(getRect(tagRef.current));
  }, [tagRef]);

  const handleGetElementRect = React.useCallback(() => {
    if (!tagRef.current) return;
    setElementRect(getRect(tagRef.current));
  }, [tagRef]);

  React.useEffect(() => {
    if (!elementRect) return;

    //Positions relavtive to top of viewport
    if (elementRect.top < startShwoingPosition && elementRect.top > endShwoingPosition) {
      const intensity = ratioShowing(startShwoingPosition, endShwoingPosition, elementRect.top);
      const scaleIntensity = changeScaleOfRange(intensity, minScaleValue, maxScaleValue);
      setIntensity(intensity);
      setScaleIntensity(scaleIntensity);
      return;
    } else if (elementRect.top < startHidingPosition && elementRect.top > endHidingPosition) {
      const intensity = ratioHiding(startHidingPosition, endHidingPosition, elementRect.top);
      const scaleIntensity = changeScaleOfRange(intensity, minScaleValue, maxScaleValue);
      setIntensity(intensity);
      setScaleIntensity(scaleIntensity);
      return;
    } else if (startShwoingPosition < elementRect.top || elementRect.top < endHidingPosition) {
      setIntensity(0);
      setScaleIntensity(minScaleValue);
      return;
    } else {
      setScaleIntensity(maxScaleValue);
      setIntensity(1);
      return;
    }
  }, [
    elementRect,
    startShwoingPosition,
    endShwoingPosition,
    startHidingPosition,
    endHidingPosition,
    minScaleValue,
    maxScaleValue
  ]);

  React.useEffect(() => {
    if (!tagRef.current) throw Error("tagRef is not assigned");

    window.addEventListener("scroll", handleGetElementRect);
    return () => window.removeEventListener("scroll", handleGetElementRect);
  });

  return { tagRef, intensity, scaleIntensity };
};

export default useIntensityTransform;
