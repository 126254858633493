export const partnershipsImages = [
  {
    desktop: "/images/svg/partnership-bsc-news.svg",
    mobile: "/images/svg/partnership-bsc-news-mobile.svg"
  },
  {
    desktop: "/images/svg/partnership-market-watch.svg",
    mobile: "/images/svg/partnership-market-watch-mobile.svg"
  },
  {
    desktop: "/images/svg/partnership-cointelegraph.svg",
    mobile: "/images/svg/partnership-cointelegraph-mobile.svg"
  },
  {
    desktop: "/images/svg/partnership-business-insider.svg",
    mobile: "/images/svg/partnership-business-insider-mobile.svg"
  },
  {
    desktop: "/images/svg/partnership-certik.svg",
    mobile: "/images/svg/partnership-certik-mobile.svg"
  },
  {
    desktop: "/images/svg/partnership-yahoo-finance.svg",
    mobile: "/images/svg/partnership-yahoo-finance-mobile.svg"
  }
];
