import React from "react";
import SocialIconLink from "../../atoms/SocialIconLink/SocialIconLink";
import WithTooltip from "../../molecules/WithTooltip/WithTooltip";
import Link from "../../atoms/Link/Link";
import { ISocialMediaBar } from "../../../../shared/interfaces/ISocialMediaBar";
import "./SocialMediaBar.scss";

const SocialMediaBar = ({
  socialMediaList,
  socialIconVariant,
  sticky = false,
  className
}: ISocialMediaBar) => {
  const socialMediaBarClass = `${sticky ? "sticky-social-media-bar" : "social-media-bar "} ${className}`;

  return (
    <div className={socialMediaBarClass}>
      {socialMediaList.map(({ url, logoPaths, channels }) => {
        return channels && channels?.length > 0 ? (
          <WithTooltip
            key={Math.random()}
            alwaysShown={<SocialIconLink logoPath={logoPaths[socialIconVariant]} withTooltip />}
            tooltipPosition="left"
            tooltipInside={
              <>
                {channels?.map(({ label, url }) => {
                  return (
                    <Link key={Math.random()} url={url}>
                      {label}
                    </Link>
                  );
                })}
              </>
            }
          />
        ) : (
          <SocialIconLink key={Math.random()} url={url} logoPath={logoPaths[socialIconVariant]} />
        );
      })}
    </div>
  );
};

export default SocialMediaBar;
