import React from "react";
import { Container } from "react-bootstrap";
import ButtonLink from "../../components/atoms/ButtonLink/ButtonLink";
import { BUY_TOKEN } from "../../../constants";
import "./BuyNow.scss";

const aosBaseAnimConfig = {
  "data-aos": "fade-up",
  "data-aos-easing": "linear",
  "data-aos-duration": "350",
  "data-aos-once": "false"
};

const BuyNow = () => {
  return (
    <section id="buynow" className="buynow">
      <Container fluid="xxl">
        <img
          src="/images/png/buynow-bg-coins-left-desktop.png"
          alt="Coins"
          className="buynow-coins-bg-left desktop-md"
        />
        <Container fluid="xl">
          <div className="buynow-content">
            <h1 {...aosBaseAnimConfig} data-aos-offset="200" className="header">
              Passive income guaranteed!
            </h1>
            <div {...aosBaseAnimConfig} data-aos-offset="200">
              <p className="description">
                $BUFF holders will have an influence on the crucial aspects of platform, the game and the
                revenue.
              </p>
            </div>
            <div className="buynow-button-wrapper">
              <div {...aosBaseAnimConfig} data-aos-offset="200" className="button-animation">
                <ButtonLink url={BUY_TOKEN} size="size-big" color="secondary" className="button">
                  Buy now!
                </ButtonLink>
              </div>
              <div className="button-blur" />
            </div>
          </div>
        </Container>
        <img
          src="/images/png/buynow-bg-coins-right-desktop.png"
          alt="Coins"
          className="buynow-coins-bg-right desktop-md"
        />
        <div {...aosBaseAnimConfig} data-aos-offset="300">
          <img
            src="/images/png/buynow-bg-coins-mobile.png"
            alt="Coins"
            className="buynow-coins-bg-mobile mobile-md"
          />
        </div>
      </Container>
    </section>
  );
};

export default BuyNow;
