import React from "react";
import { IButtonLink } from "../../../../shared/interfaces/IButtonLink";
import "./ButtonLink.scss";

const ButtonLink = ({
  url,
  target = "_blank",
  children,
  download = false,
  variant = "contained",
  color = "primary",
  size = "size-normal",
  icon,
  className = "",
  disabled = false
}: IButtonLink) => {
  const linkIcon = icon && <img src={icon} alt="link icon" className="button-link-icon" />;

  const containedClass = `button-link contained-link ${color} ${size} ${className} 
      ${disabled ? "disabled" : ""}`;
  const outlinedClass = `button-link outlined-link ${color} ${size} ${className} ${
    disabled ? "disabled" : ""
  }`;
  const buildClass = variant === "contained" ? containedClass : outlinedClass;

  return (
    <a href={url} className={buildClass} rel="noreferrer noopener" download={download} target={target}>
      {children}
      {linkIcon}
    </a>
  );
};

export default ButtonLink;
