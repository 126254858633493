const colors = ["red", "green", "blue"];

export const convertRbgColorToObject = (color: string) => {
  let obj: any = {};
  let colorArr = color.slice(color.indexOf("(") + 1, color.indexOf(")")).split(", ");

  colorArr.forEach((k, i) => {
    obj[colors[i]] = k;
  });

  return obj;
};
