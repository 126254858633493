import React, { useState } from "react";
import Tooltip from "../../atoms/Tooltip/Tooltip";
import { IWithTooltip } from "../../../../shared/interfaces/IWithTooltip";
import "./WithTooltip.scss";

const WithTooltip = ({
  alwaysShown,
  tooltipInside,
  isOpen = false,
  tooltipPosition = "left",
  handleToggle,
  handleClose,
  className = "",
  externalClassForTooltip = ""
}: IWithTooltip) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleInnerOpen = () => setMenuOpen(true);
  const handleInnerClose = () => {
    setTimeout(() => {
      setMenuOpen(false);
    }, 150);
  };

  const handleInnerToggle = () => {
    if (menuOpen) {
      handleInnerClose();
    } else {
      handleInnerOpen();
    }
  };

  return (
    <button
      className={`with-tooltip ${className}`}
      onClick={handleToggle ? handleToggle : handleInnerToggle}
      onBlur={handleClose ? handleClose : handleInnerClose}
    >
      {alwaysShown}
      {(isOpen || menuOpen) && (
        <Tooltip className={`${externalClassForTooltip} ${tooltipPosition}`} children={tooltipInside} />
      )}
    </button>
  );
};

export default WithTooltip;
