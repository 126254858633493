import { useEffect, useState } from "react";
import { debounce as debounceFunc } from "../services/debounce";

/** 
  threshold: scroll direction won't change if scrollY - lastScrollY < threshold 
  debounce: debounce time to set the new scroll direction (default: 100)
**/

type ScrollDirectionType = "" | "up" | "down";

export enum ScrollDirections {
  UP = "up",
  DOWN = "down"
}

const useScrollYDirection = (threshold: number = 10, debounce: number = 100) => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirectionType>("");
  const debouncedSetScrollDir = debounceFunc(setScrollDirection, debounce);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const newDirection = scrollY > lastScrollY ? ScrollDirections.DOWN : ScrollDirections.UP;

      if (Math.abs(scrollY - lastScrollY) < threshold) return;

      if (newDirection !== scrollDirection) {
        debouncedSetScrollDir(newDirection);
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection, threshold, debouncedSetScrollDir]);

  return scrollDirection;
};

export default useScrollYDirection;
