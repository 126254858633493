import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { convertRbgColorToObject } from "../../../../services/convertRgbColorToObject";
import { getRect } from "../../../../services/getRect";
import { RectResult } from "../../../../shared/types/RectResult";
import { RGBColor } from "../../../../shared/types/RGBColor";
import { RGBColorObject } from "../../../../shared/types/RGBColorObject";

interface AnimationBoxProps {
  firstColor: RGBColor;
  secondColor: RGBColor;
  children: ReactElement[];
}

const colorScale = (height: number, startPoint: number, endPoint: number) =>
  (height / 2 - startPoint) / (endPoint - startPoint);

const calcColor = (firstColor: RGBColorObject, secondColor: RGBColorObject, scale: number): RGBColor => {
  const r = firstColor.red - (firstColor.red - secondColor.red) * scale;
  const g = firstColor.green - (firstColor.green - secondColor.green) * scale;
  const b = firstColor.blue - (firstColor.blue - secondColor.blue) * scale;

  return `rgb(${r}, ${g}, ${b})`;
};

const BgColorAnimationBox = ({ firstColor, secondColor, children }: AnimationBoxProps) => {
  const [boxRect, setBoxRect] = useState<RectResult>(getRect());
  const [bgColor, setBgColor] = useState<RGBColor>();
  const ref = useRef<HTMLDivElement>(null);

  const { height: viewportHeight } = useWindowDimensions();

  useEffect(() => {
    if (!ref.current) return;
    setBoxRect(getRect(ref.current));
  }, []);

  const startChangingPoint = boxRect.top + boxRect.height * 0.375;
  const endChangingPoint = boxRect.top + boxRect.height * 0.625;

  const handleGetBoxRect = React.useCallback(() => {
    if (!ref.current) return;
    setBoxRect(getRect(ref.current));
  }, [ref]);

  useEffect(() => {
    if (viewportHeight / 2 < startChangingPoint) {
      setBgColor(firstColor);
    } else if (viewportHeight / 2 > endChangingPoint) {
      setBgColor(secondColor);
    } else {
      const scale = colorScale(viewportHeight, startChangingPoint, endChangingPoint);
      const firstColorObj = convertRbgColorToObject(firstColor);
      const secondColorObj = convertRbgColorToObject(secondColor);
      const newColor = calcColor(firstColorObj, secondColorObj, scale);

      setBgColor(newColor);
    }
  }, [startChangingPoint, endChangingPoint, viewportHeight, firstColor, secondColor]);

  useEffect(() => {
    if (!ref.current) throw Error("ref is not assigned");

    window.addEventListener("scroll", handleGetBoxRect);
    return () => window.removeEventListener("scroll", handleGetBoxRect);
  });

  return (
    <div ref={ref} style={{ backgroundColor: bgColor }}>
      {children}
    </div>
  );
};

export default BgColorAnimationBox;
