export const MOBILE_BREAKPOINT = 768;

// SOCIAL MEDIA LINK'S
export const MEDIUM = "https://buffswap.medium.com/";
export const TWITTER = "https://twitter.com/buff_swap";
export const YOUTUBE = "https://www.youtube.com/channel/UC33_vehzmHQWPvO6KLMwhnw";
export const TELEGRAM_NEWS = "https://t.me/BUFFSNews";
export const TELEGRAM_CHAT = "https://t.me/BUFFSChat";

// CONTACT EMAIL'S
export const BUFFS_OFFICE = "Office@buffswap.io";
export const BUFFS_SUPPORT = "Support@buffswap.io";
export const BUFFS_TRADING = "Trading@buffswap.io";

// VIDEO
export const BUFF_VIDEO = "https://www.youtube.com/embed/jA_iflftl44";

//BUTTON LINKS
export const CLAIM = "https://claim.buffswap.io/";
export const BUY_ON_PANCAKE_SWAP =
  "https://pancakeswap.finance/swap?outputCurrency=0x140B890BF8e2FE3E26fcD516c75728fB20b31c4f";
export const CERTIK_SECURITY_AUDIT = "https://www.certik.com/projects/buffswap";
export const PANCAKE_BUY =
  "https://pancakeswap.finance/swap?outputCurrency=0x140B890BF8e2FE3E26fcD516c75728fB20b31c4f";
export const PANCAKE_VIEW_CHART =
  "https://pancakeswap.finance/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&inputCurrency=0x140B890BF8e2FE3E26fcD516c75728fB20b31c4f";
export const LITEPAPER = "/assets/BuffSwap-Litepaper.pdf";
export const BUY_TOKEN =
  "https://pancakeswap.finance/swap?outputCurrency=0x140B890BF8e2FE3E26fcD516c75728fB20b31c4f";

//BUFFS TOKEN
export const BUFFS_TOKEN_NAME = "BuffSwap";
export const BUFFS_TOKEN_SYMBOL = "BUFFS";
export const BUFFS_TOKEN_DECIMALS = 4;
export const BUFFS_TOKEN_ADDRESS = "0x140B890BF8e2FE3E26fcD516c75728fB20b31c4f";
