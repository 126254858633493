export const DEVIATION_INTENSITY = 0.04;

export const movableArrowImages = [
  {
    src: "/images/svg/blockchain-info-blue-arrow.svg",
    alt: "blue arrow",
    className: "blue-arrow"
  },
  {
    src: "/images/svg/blockchain-info-cyan-arrow.svg",
    alt: "cyan arrow",
    className: "cyan-arrow"
  },
  {
    src: "/images/svg/blockchain-info-purple-arrow.svg",
    alt: "purple arrow",
    className: "purple-arrow"
  },
  {
    src: "/images/svg/blockchain-info-green-arrow.svg",
    alt: "green arrow",
    className: "green-arrow"
  },
  {
    src: "/images/svg/blockchain-info-white-arrow.svg",
    alt: "white arrow",
    className: "white-arrow"
  },
  {
    src: "/images/svg/blockchain-info-green-arrow2.svg",
    alt: "green arrow",
    className: "green-arrow-bottom"
  }
];
