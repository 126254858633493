import React from "react";
import useViewport from "../../../hooks/useViewport";
import { MOBILE_BREAKPOINT } from "../../../constants";
import { socialMedia } from "../../../data/socialMedia";
import { RGBColor } from "../../../shared/types/RGBColor";
import BgColorAnimationBox from "../../components/organisms/BgColorAnimationBox/BgColorAnimationBox";
import NavbarDesktop from "../../components/organisms/NavbarDesktop/NavbarDesktop";
import NavbarMobile from "../../components/organisms/NavbarMobile/NavbarMobile";
import SocialMediaBar from "../../components/organisms/SocialMediaBar/SocialMediaBar";
import Hero from "../../sections/Hero/Hero";
// import BuffVideo from "../../sections/BuffVideo/BuffVideo";
import TokenDetails from "../../sections/TokenDetails/TokenDetails";
import Certik from "../../sections/Certik/Certik";
import PancakeSwap from "../../sections/PancakeSwap/PancakeSwap";
import BlockchainInfo from "../../sections/BlockchainInfo/BlockchainInfo";
import UseCases from "../../sections/UseCases/UseCases";
import Litepaper from "../../sections/Litepaper/Litepaper";
import Roadmap from "../../sections/Roadmap/Roadmap";
import BuyNow from "../../sections/BuyNow/BuyNow";
import Footer from "../../sections/Footer/Footer";
import "./LandingPage.scss";

const CERTIK_BG_COLOR: RGBColor = "rgb(82, 33, 130)";
const PANCAKE_SWAP_BG_COLOR: RGBColor = "rgb(1, 46, 62)";

const LandingPage = () => {
  const { witdh } = useViewport();
  const isMobile = witdh < MOBILE_BREAKPOINT;

  return (
    <div id="landing-page">
      {!isMobile && <SocialMediaBar socialMediaList={socialMedia} socialIconVariant="stickyBar" sticky />}
      {isMobile ? <NavbarMobile /> : <NavbarDesktop />}
      <Hero />
      {/* <BuffVideo /> */}
      <TokenDetails />
      <BgColorAnimationBox firstColor={CERTIK_BG_COLOR} secondColor={PANCAKE_SWAP_BG_COLOR}>
        <Certik />
        <PancakeSwap />
      </BgColorAnimationBox>
      <BlockchainInfo />
      <UseCases />
      <Litepaper />
      <Roadmap />
      <BuyNow />
      <Footer />
    </div>
  );
};

export default LandingPage;
