import React from "react";
import { MOBILE_BREAKPOINT } from "../../../../constants";
import useViewport from "../../../../hooks/useViewport";
import { IPartnershipsBar } from "../../../../shared/interfaces/IPartnershipsBar";
import "./PartnershipsBar.scss";

const PartnershipsBar = ({ partnershipsImages, className }: IPartnershipsBar) => {
  const { witdh } = useViewport();
  const isMobile = witdh < MOBILE_BREAKPOINT;

  return (
    <div className={`partnerhips-bar ${className}`}>
      {partnershipsImages.map(({ desktop, mobile }) => (
        <img key={Math.random()} src={isMobile ? mobile : desktop} alt="partnership logo" />
      ))}
    </div>
  );
};

export default PartnershipsBar;
