import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LandingPage from "./ui/pages/LandingPage/LandingPage";

const getFaviconPath = (isDarkMode = false) => {
  return `/favicon-${isDarkMode ? "dark" : "light"}.png`;
};

const App = () => {
  const [faviconHref, setFaviconHref] = useState("/favicon-light.png");

  useEffect(() => {
    const matcher = window.matchMedia("(prefers-color-scheme: dark)");
    setFaviconHref(getFaviconPath(matcher.matches));
    matcher.onchange = () => setFaviconHref(getFaviconPath(matcher.matches));
  }, [faviconHref]);

  return (
    <>
      <Helmet>
        <link rel="icon" href={faviconHref} />
      </Helmet>
      <LandingPage />
    </>
  );
};

export default App;
