import React from "react";
import { IButton } from "../../../../shared/interfaces/IButton";
import "./Button.scss";

const Button = ({
  children,
  onClick = () => {},
  variant = "contained",
  color = "primary",
  size = "size-normal",
  icon,
  disabled = false,
  className = ""
}: IButton) => {
  const buttonIcon = icon && <img src={icon} alt="button icon" className="buff-button-icon" />;

  const containedClass = `buff-button buff-contained-button ${color} ${size} ${className} 
      ${disabled ? "disabled" : ""}`;
  const outlinedClass = `buff-button buff-outlined-button ${color} ${size} ${className} ${
    disabled ? "disabled" : ""
  }`;
  const buildClass = variant === "contained" ? containedClass : outlinedClass;

  return (
    <button className={buildClass} disabled={disabled} onClick={onClick}>
      {children}
      {buttonIcon}
    </button>
  );
};

export default Button;
