import { useEffect, useMemo, useState } from "react";
import useScrollYDirection, { ScrollDirections } from "../../../../hooks/useScrollYDirection";
import "./RoadmapHeader.scss";

const HEADER_ANIMATION_TRANSITION = 500;

const baseObserverConfig: IntersectionObserverInit = {
  root: null,
  threshold: 0
};

const RoadmapHeader = ({ className = "" }) => {
  const [headerTitle, setHeaderTitle] = useState("");
  const [doAnimate, setDoAnimate] = useState(false);

  const scrollDirection = useScrollYDirection();
  const rootMargin = ScrollDirections.DOWN === scrollDirection ? "-75% 0px -25% 0px" : "-25% 0px -75% 0px";
  const observerConfig = { ...baseObserverConfig, rootMargin };

  useEffect(() => {
    const firsTitle =
      document.querySelectorAll("[data-quarter-year]")[0].getAttribute("data-quarter-year") || "";
    setHeaderTitle(firsTitle);
  }, []);

  const handleCallback = ([entry]: IntersectionObserverEntry[]): void => {
    if (entry.isIntersecting) {
      const quarterTitle = entry.target.getAttribute("data-quarter-year") || "";
      setHeaderTitle(quarterTitle);
    }
  };

  useEffect(() => {
    const nodesList = document.querySelectorAll("[data-quarter-year]");
    const observer = new IntersectionObserver(handleCallback, observerConfig);

    nodesList.forEach((node) => {
      observer.observe(node);
    });

    return () => observer.disconnect();
  });

  useMemo(() => {
    setDoAnimate(true);
    setTimeout(() => {
      setDoAnimate(false);
    }, HEADER_ANIMATION_TRANSITION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerTitle]);

  const roadmapHeaderClass = `roadmap-header ${doAnimate ? "roadmap-header-animation" : ""} ${className} `;

  return (
    <div className={roadmapHeaderClass}>
      <h2>{headerTitle}</h2>
    </div>
  );
};

export default RoadmapHeader;
