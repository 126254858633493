import React from "react";
import RoadmapItem from "../../atoms/RoadmapItem/RoadmapItem";
import { IRoadmapQuarterList } from "../../../../shared/interfaces/IRoadmapQuarterList";
import "./RoadmapQuarterList.scss";

const RoadmapQuarterList = ({ quarterYear, quarterList, className = "" }: IRoadmapQuarterList) => {
  return (
    <div className={`roadmap-quarter-list ${className}`} data-quarter-year={quarterYear}>
      {quarterList.map(({ text, status }) => (
        <RoadmapItem key={Math.random()} status={status} text={text} />
      ))}
    </div>
  );
};

export default RoadmapQuarterList;
