import React from "react";
import "./RoadmapItemLoader.scss";

const RoadmapItemLoader = ({ className = "" }) => {
  return (
    <div className={`roadmap-item-loader ${className}`}>
      <img src="/images/svg/roadmap-item-loader.svg" alt="loader" />
    </div>
  );
};

export default RoadmapItemLoader;
