import React from "react";
import { Container } from "react-bootstrap";
import Link from "../../components/atoms/Link/Link";
import ScrollLink from "../../components/atoms/ScrollLink/ScrollLink";
import SocialMediaBar from "../../components/organisms/SocialMediaBar/SocialMediaBar";
import { BUFFS_OFFICE, BUFFS_SUPPORT, BUFFS_TRADING, CLAIM } from "../../../constants";
import { footerScrollToLinks } from "../../../data/footerScrollToLinks";
import { socialMedia } from "../../../data/socialMedia";
import "./Footer.scss";

const Footer = () => {
  return (
    <section id="footer" className="footer">
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="footer-container">
            <ScrollLink to="root">
              <img src="/images/svg/footer-buff-logo.svg" alt="buffswap logo" className="footer-logo" />
            </ScrollLink>

            <div className="links-wrapper">
              <div className="footer-scroll-links">
                {footerScrollToLinks.map(({ label, to, offsetTopDesktop, offsetTopMobile }) => (
                  <ScrollLink
                    key={Math.random()}
                    to={to}
                    offsetTopDesktop={offsetTopDesktop}
                    offsetTopMobile={offsetTopMobile}
                  >
                    {label}
                  </ScrollLink>
                ))}
                <Link url={CLAIM}>Claim</Link>
              </div>

              <div className="contacts-links">
                <div className="social-media">
                  <SocialMediaBar socialMediaList={socialMedia} socialIconVariant="footer" />
                </div>
                <div className="contacts">
                  <Link url={`mailto:${BUFFS_OFFICE}`}>{BUFFS_OFFICE}</Link>
                  <Link url={`mailto:${BUFFS_SUPPORT}`}>{BUFFS_SUPPORT}</Link>
                  <Link url={`mailto:${BUFFS_TRADING}`}>{BUFFS_TRADING}</Link>
                </div>
              </div>
            </div>

            <div className="policy-wrapper">
              <p className="s2 policy">BUFF-$WAP 2022. All rights reserved</p>
              <ScrollLink to="root" icon="/images/svg/back-to-top-arrow.svg" className="back-top-btn">
                Back to top
              </ScrollLink>
            </div>
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default Footer;
