import { useEffect, useState } from "react";

const useViewport = () => {
  const [witdh, setWitdh] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWitdh(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { witdh };
};

export default useViewport;
