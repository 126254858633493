import React from "react";
import { IUseCardCard } from "../../../../shared/interfaces/IUseCaseCard";
import "./UseCaseCard.scss";

const aosAnimBaseConfig = {
  "data-aos": "fade-up",
  "data-aos-easing": "linear",
  "data-aos-duration": "350",
  "data-aos-once": "false",
  "data-aos-offset": "200"
};

const UseCaseCard = ({ title, description, iconPath, className }: IUseCardCard) => {
  return (
    <div className={`use-case-card ${className}`}>
      <div {...aosAnimBaseConfig}>
        <img src={iconPath} alt="card icon" />
      </div>
      <h2 {...aosAnimBaseConfig} className="case-title">
        {title}
      </h2>
      <div {...aosAnimBaseConfig}>
        <p className="case-description">{description}</p>
      </div>
    </div>
  );
};

export default UseCaseCard;
