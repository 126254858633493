import React from "react";
import { Container } from "react-bootstrap";
import ButtonLink from "../../components/atoms/ButtonLink/ButtonLink";
import { CERTIK_SECURITY_AUDIT } from "../../../constants";
import "./Certik.scss";

const aosBaseAnimConfig = {
  "data-aos": "zoom-in",
  "data-aos-easing": "linear",
  "data-aos-duration": "350",
  "data-aos-once": "false"
};

const Certik = () => {
  return (
    <section id="certik" className="certik">
      <Container fluid="xxl">
        <Container fluid="xl" className="certik-container">
          <div {...aosBaseAnimConfig} data-aos-offset="200" className="certik-content">
            <div className="hero-box">
              <img
                src="/images/svg/hero-certik-desktop.svg"
                alt="section logo"
                className="desktop-md hero-image"
              />
              <img
                src="/images/svg/hero-certik-mobile.svg"
                alt="section logo"
                className="mobile-md hero-image"
              />
              <div className="hero-blur" />
            </div>

            <p className="description">
              Certik uses rigorous Formal Verification technology to provide hacker-resistant smart contract
              and blockchain audits. BuffSwap smart contract is positive verified by Certik!
            </p>

            <div {...aosBaseAnimConfig} data-aos-offset="150">
              <ButtonLink
                url={CERTIK_SECURITY_AUDIT}
                color="secondary"
                icon="/images/svg/arrow-right-icon-small.svg"
                className="certik-button"
              >
                Check security audit
              </ButtonLink>
            </div>
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default Certik;
