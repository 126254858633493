import React from "react";
import { INavListDesktop } from "../../../../shared/interfaces/INavListDesktop";
import MenuItem from "../../atoms/MenuItem/MenuItem";
import "./NavListDesktop.scss";

const NavListDesktop = ({ list }: INavListDesktop) => {
  return (
    <div className="nav-list-desktop">
      {list.map(({ label, sectionID, offsetTopDesktop }) => (
        <MenuItem
          key={Math.random()}
          label={label}
          sectionID={sectionID}
          offsetTopDesktop={offsetTopDesktop}
        />
      ))}
    </div>
  );
};

export default NavListDesktop;
