import React from "react";
import { INavListMobile } from "../../../../shared/interfaces/INavListMobile";
import MenuItem from "../../atoms/MenuItem/MenuItem";
import "./NavListMobile.scss";

const NavListMobile = ({ list, close }: INavListMobile) => {
  return (
    <div className="nav-list-mobile">
      {list.map(({ label, sectionID, offsetTopMobile }) => (
        <MenuItem
          key={Math.random()}
          label={label}
          sectionID={sectionID}
          offsetTopMobile={offsetTopMobile}
          onClick={close}
          isMobile
        />
      ))}
    </div>
  );
};

export default NavListMobile;
