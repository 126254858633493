import React from "react";
import { IHoverIcon } from "../../../../shared/interfaces/IHoverIcon";
import "./HoverIcon.scss";

const HoverIcon = ({ iconPath, altText = "icon", isHover, doHover = true, className = "" }: IHoverIcon) => {
  const divider = ".";
  const parts = iconPath.split(divider);

  const pathBase = parts[0];
  const extension = parts[1];

  const iconUnhovered = iconPath;
  const iconHovered = `${pathBase}-hover.${extension}`;

  const hoverIconClass = `hover-icon ${className}`;

  const withHover = (
    <>
      <img src={iconUnhovered} alt={altText} hidden={isHover} className={hoverIconClass} />
      <img src={iconHovered} alt={`${altText} - hovered`} hidden={!isHover} className={hoverIconClass} />
    </>
  );

  const withoutHover = <img src={iconUnhovered} alt={altText} className={hoverIconClass} />;

  return (
    <>
      {doHover && <>{withHover}</>}
      {!doHover && <>{withoutHover}</>}
    </>
  );
};

export default HoverIcon;
