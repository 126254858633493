export const footerScrollToLinks = [
  {
    label: "Home",
    to: "root",
    offsetTopDesktop: -45,
    offsetTopMobile: 0
  },
  {
    label: "Token",
    to: "token-details",
    offsetTopDesktop: 0,
    offsetTopMobile: 0
  },
  {
    label: "Litepaper",
    to: "litepaper",
    offsetTopDesktop: -45,
    offsetTopMobile: 0
  },
  {
    label: "Roadmap",
    to: "roadmap",
    offsetTopDesktop: -45,
    offsetTopMobile: 0
  }
];
